<template>
  <div class="delivery">
    <div class="header d-flex flex-row">
      <div class="left d-flex flex-column justify-center">
        <h3>How do we deliver your Bouquets?</h3>
        <p>Explore how we deliver your orders</p>

        <div class="scroll">
          <img
            src="@/assets/images/Delivery/scroll.png"
            alt="scroll"
            class="scroll-arrow"
          />
        </div>
      </div>
      <div class="right d-flex flex-row align-center">
        <img
          src="@/assets/images/Delivery/laptop-flowers.png"
          alt="laptop-flower"
        />
      </div>
    </div>

    <div class="secure d-flex flex-row align-center space-around">
      <img
        src="@/assets/icons/Others/gift.png"
        alt="gift"
        class="our-app-icon"
      />
      <div class="left">
        <img
          src="@/assets/images/Delivery/secure.png"
          alt="personalize.png"
          class="package"
        />
      </div>
      <div class="right">
        <h5>Packaging</h5>

        <h3>Secure packaging</h3>
        <p>
          Each order we pack in our secure box. You can be sure that you bouquet
          will look terrific. It is completely for free
        </p>
      </div>
    </div>

    <div class="our-partners">
      <h3>Our delivery patners</h3>

      <div
        class="our-partners__block d-flex flex-row align-center justify-center"
      >
        <img src="@/assets/images/Delivery/partner1.png" alt="partner1.png" />
        <img src="@/assets/images/Delivery/partner2.png" alt="partner2.png" />
        <img src="@/assets/images/Delivery/partner3.png" alt="partner3.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "Delivery",
  components: {
    Button,
  },
};
</script>

